import React from 'react';

import Layout from '../containers/Layout';
import Search from '../containers/Search';

const AuthorsPage = ({ pageContext, _, location }) => (
  <Layout title={`${pageContext.author.user_name} ${pageContext.author.user_surname}`} description={`Articles by ${pageContext.author.user_name} ${pageContext.author.user_surname}`} location={location.href}>
    <Search author={pageContext.author} />
  </Layout>
);

export default AuthorsPage;
